import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';
import { Icon, IconName } from 'components/icons';
import { Bodycopy } from 'components/typographies';
import { Squared } from 'components/decorators';
import classnames from 'classnames';
import { SquareStyle } from 'components/decorators/styles';

import {
  CONTAINER,
  NOTIFICATION_SIZES,
  NOTIFICATION_STYLES,
  NotificationSize,
  NotificationStyle,
} from './styles';

interface Props {
  content: string | ReactNode;
  children?: ReactNode;
  styles?: NotificationStyle;
  size?: NotificationSize;
  className?: string;
  icon?: boolean;
}

const ICON = {
  regular: 'info',
  warning: 'warning',
  success: 'happy',
};

const Notification: FunctionComponent<Props> = (
  {
    content,
    children,
    styles = 'regular',
    size = 'large',
    className = '',
    icon = true,
  }: Props,
) => (
  <div
    className={classNames(
      CONTAINER,
      NOTIFICATION_STYLES[styles],
      NOTIFICATION_SIZES[size],
      className,
    )}
  >
    {icon && (
      <Squared
        size="medium"
        styles={styles as SquareStyle}
        className="p-2 absolute -top-[19px] left-[calc(50%-19px)] md:static md:mr-6"
      >
        <Icon
          // @ts-ignore
          name={ICON[styles] as IconName}
        />
      </Squared>
    )}
    {typeof content !== 'string' ? content : (
      <Bodycopy tag="div" className={classnames('mb-2 text-center md:mb-0 md:text-left', { 'max-w-2xl': children })}>
        {content}
      </Bodycopy>
    )}
    <div className="md:ml-auto">
      {children}
    </div>
  </div>
);

export default Notification;
