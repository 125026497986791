import { saveAs } from 'file-saver';
import { AppDocument } from 'models';
import _ from 'lodash/fp';

import t from '../locales';

const classNames = (...classes: (string | undefined)[]): string => classes.filter(Boolean).join(' ');

const download = (document: AppDocument): void => {
  const extension = _.flow(
    _.split('.'),
    _.last,
  )(document.filename);

  saveAs(document.url, `${t(`models.documents.${document.docType}`)}.${extension}`);
};

const fancyNumber = (value: number, fixed = 2): string => {
  const fixedNumber = parseFloat(value.toFixed(fixed));

  return fixedNumber.toLocaleString(undefined, { useGrouping: true });
};

const divide = (value: number): number => (
  _.isEqual(value, 0) ? value : value / 1000
);

const isCurrentMonthInRange = (fromDate: Date | null, toDate: Date | null): boolean => {
  if (!fromDate || !toDate) return false;

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const fromMonth = fromDate.getMonth();
  const fromYear = fromDate.getFullYear();
  const toMonth = toDate.getMonth();
  const toYear = toDate.getFullYear();

  if (currentYear < fromYear || currentYear > toYear) {
    return false;
  }

  if (currentYear === fromYear && currentYear === toYear) {
    return currentMonth >= fromMonth && currentMonth <= toMonth;
  }

  if (currentYear === fromYear) {
    return currentMonth >= fromMonth;
  }

  if (currentYear === toYear) {
    return currentMonth <= toMonth;
  }

  return true;
};

export {
  classNames,
  download,
  fancyNumber,
  divide,
  isCurrentMonthInRange,
};
